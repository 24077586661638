import React from "react";
import { Box } from "@mui/material";

const ImageBox = ({
  image,
  width,
  top,
  bottom,
  left,
  right,
  rotation,
  flip,
}) => {
  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        position: "absolute",
        top: top,
        bottom: bottom,
        left: left,
        right: right,
        zIndex: 0,
        transform: `scaleX(${flip})`,
      }}
    >
      <img
        src={image}
        alt="image"
        width={width}
        style={{
          transform: `rotate(${rotation}deg)`,
          inset: 0,
          margin: "auto",
        }}
      />
    </Box>
  );
};

export default ImageBox;
