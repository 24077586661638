import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import ImageBox from "./ImageBox";

const beerImage = "../../images/pictures/beer.png";
const energyImage = "../../images/pictures/monster.png";
const cocktailImage = "../../images/pictures/cocktail.png";
const vodkaImage = "../../images/pictures/absolut.png";
const champagneImage = "../../images/pictures/champagne.png";
const wineImage = "../../images/pictures/wine.png";
const creamImage = "../../images/pictures/cream.png";
const whiskeyImage = "../../images/pictures/whiskey.png";
const cognacImage = "../../images/pictures/cognac.png";
const psoupImage = "../../images/pictures/peppersoup.png";
const shawarmaImage = "../../images/pictures/shawarma.png";
const grillImage = "../../images/pictures/grill.png";

const ImageSeries = () => {
  const isTab = useMediaQuery("(max-width:750px)");

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <ImageBox
        image={beerImage}
        width={isTab ? "175px" : "270px"}
        top={isTab ? "390px" : "390px"}
        left={isTab ? "210px" : "350px"}
        rotation={10}
      />
      <ImageBox
        image={energyImage}
        width={isTab ? "200px" : "250px"}
        top={isTab ? "740px" : "900px"}
        right={isTab ? "180px" : "320px"}
        rotation={-20}
      />
      <ImageBox
        image={cocktailImage}
        width={isTab ? "115px" : "160px"}
        top={isTab ? "970px" : "1200px"}
        left={isTab ? "250px" : "400px"}
      />
      <ImageBox
        image={vodkaImage}
        width={isTab ? "180px" : "250px"}
        top={isTab ? "1130px" : "1390px"}
        right={isTab ? "200px" : "350px"}
        rotation={10}
      />
      <ImageBox
        image={champagneImage}
        width={isTab ? "200px" : "300px"}
        top={isTab ? "1420px" : "1750px"}
        left={isTab ? "200px" : "350px"}
        flip="-1"
      />
      <ImageBox
        image={wineImage}
        width={isTab ? "150px" : "200px"}
        top={isTab ? "1650px" : "2050px"}
        right={isTab ? "250px" : "400px"}
        flip="-1"
      />
      <ImageBox
        image={creamImage}
        width={isTab ? "250px" : "350px"}
        top={isTab ? "1950px" : "2450px"}
        left={isTab ? "170px" : "300px"}
      />
      <ImageBox
        image={whiskeyImage}
        width={isTab ? "300px" : "450px"}
        top={isTab ? "2310px" : "2900px"}
        right={isTab ? "155px" : "250px"}
      />
      <ImageBox
        image={cognacImage}
        width={isTab ? "200px" : "320px"}
        top={isTab ? "2690px" : "3430px"}
        left={isTab ? "205px" : "320px"}
      />
      <ImageBox
        image={psoupImage}
        width={isTab ? "150px" : "250px"}
        top={isTab ? "2965px" : "3810px"}
        left={isTab ? "230px" : "350px"}
      />
      <ImageBox
        image={shawarmaImage}
        width={isTab ? "150px" : "250px"}
        top={isTab ? "3900px" : "5000px"}
        right={isTab ? "220px" : "330px"}
        rotation={-30}
      />
      <ImageBox
        image={grillImage}
        width={isTab ? "180px" : "320px"}
        top={isTab ? "4175px" : "5370px"}
        left={isTab ? "220px" : "330px"}
      />
    </Box>
  );
};

export default ImageSeries;
