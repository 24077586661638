import React from "react";
import LoadingIcon from "./components/LoadingIcon";
import ImageSeries from "./components/ImageSeries";
import Header from "./global/Header";
import Body from "./pages/Body";
import Footer from "./global/Footer";
import "../src/styles/index.css";

const App = () => {
  return (
    <div className="App">
      <LoadingIcon />
      <ImageSeries />
      <Header />
      <Body />
      <Footer />
    </div>
  );
};

export default App;
