import axios from "axios";

const getProductPricesByType = (data, type) => {
  return data
    .reduce((acc, item) => {
      if (item.type === type) {
        acc.push({ product: item.product, price: item.price });
      }
      return acc;
    }, [])
    .sort((a, b) => a.product.localeCompare(b.product)); // Sort alphabetically by product name
};

const fetchData = async (url) => {
  try {
    const response = await axios.get(url);
    return response.data; // Assuming the response directly contains the data array
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export { getProductPricesByType, fetchData };
