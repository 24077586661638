import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Submenu from "../components/Submenu";
import ImageBox from "../components/ImageBox";
import { prodApi } from "../data/api";
import { getProductPricesByType, fetchData } from "../functions";

const Body = () => {
  const [data, setData] = useState([]);
  const isTab = useMediaQuery("(max-width:750px)");

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        const fetchedData = await fetchData(prodApi.prices);
        setData(fetchedData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataFromBackend();
  }, []);

  const beer = "Beer";
  const energy = "Energy Drinks";
  const soft = "Soft Drinks";
  const cocktail = "Cocktails, Juices and Yougurt";
  const vodka = "Vodka";
  const rum = "Rum";
  const tequila = "Tequila";
  const champagne = "Champagne and Sparkling Wine";
  const wine = "Red Wine";
  const nawine = "None Alcohol Wine";
  const cream = "Cream Liquor";
  const spirit = "Spirits";
  const aperitif = "Aperitif";
  const whiskey = "Whiskey vs. Whisky";
  const cognac = "Cognac";
  const water = "Water";
  const psoup = "Peppersoups";
  const meal = "Meals";
  const pmeat = "Peppered Meat";
  const shawarma = "Shawarma";
  const grill = "Grill";
  const takeaway = "Takeaway Pack";

  const beerList = getProductPricesByType(data, "beer");
  const energyList = getProductPricesByType(data, "energy drinks");
  const softList = getProductPricesByType(data, "soft drinks");
  const cocktailList = getProductPricesByType(data, "cocktails");
  const vodkaList = getProductPricesByType(data, "vodka");
  const rumList = getProductPricesByType(data, "rum");
  const tequilaList = getProductPricesByType(data, "tequila");
  const champagneList = getProductPricesByType(data, "champagne");
  const wineList = getProductPricesByType(data, "red wine");
  const nawineList = getProductPricesByType(data, "non alcoholic wine");
  const creamList = getProductPricesByType(data, "cream liquor");
  const spiritList = getProductPricesByType(data, "spirits");
  const aperitifList = getProductPricesByType(data, "aperitif");
  const whiskeyList = getProductPricesByType(data, "whiskey");
  const cognacList = getProductPricesByType(data, "cognac");
  const waterList = getProductPricesByType(data, "water");
  const psoupList = getProductPricesByType(data, "peppersoups");
  const mealList = getProductPricesByType(data, "meals");
  const pmeatList = getProductPricesByType(data, "peppered meat");
  const shawarmaList = getProductPricesByType(data, "shawarma");
  const grillList = getProductPricesByType(data, "grill");
  const takeawayList = getProductPricesByType(data, "takeaway");

  const category = {
    color: "white",
    fontSize: isTab ? "1.5rem" : "2.5rem",
    margin: "20px",
    marginTop: "40px",
    marginBottom: "0px",
    fontWeight: 100,
  };

  return (
    <>
      <Box
        sx={{
          color: "#7aa648",
          textAlign: "center",
          fontSize: "2rem",
        }}
      >
        FOOD AND DRINKS
      </Box>
      <Box sx={category}>Drinks</Box>
      <Submenu title={beer} priceList={beerList} width="50%" float="left" />
      <Submenu
        title={energy}
        priceList={energyList}
        width="40%"
        float="right"
      />
      <Submenu title={soft} priceList={softList} width="40%" float="right" />
      <Submenu
        title={cocktail}
        priceList={cocktailList}
        width="60%"
        float="left"
      />
      <Submenu title={vodka} priceList={vodkaList} width="55%" float="right" />
      <Submenu title={rum} priceList={rumList} width="55%" float="right" />
      <Submenu
        title={tequila}
        priceList={tequilaList}
        width="55%"
        float="left"
      />
      <Submenu
        title={champagne}
        priceList={champagneList}
        width="65%"
        float="left"
      />
      <Submenu title={wine} priceList={wineList} width="55%" float="right" />
      <Submenu
        title={nawine}
        priceList={nawineList}
        width="55%"
        float="right"
      />
      <Submenu title={cream} priceList={creamList} width="50%" float="left" />
      <Submenu title={spirit} priceList={spiritList} width="50%" float="left" />
      <Submenu
        title={aperitif}
        priceList={aperitifList}
        width="50%"
        float="left"
      />
      <Submenu
        title={whiskey}
        priceList={whiskeyList}
        width="55%"
        float="right"
      />
      <Submenu title={cognac} priceList={cognacList} width="55%" float="left" />
      <Submenu title={water} priceList={waterList} width="55%" float="left" />
      <Box sx={category}>Food</Box>
      <Submenu title={psoup} priceList={psoupList} width="55%" float="left" />
      <Submenu title={meal} priceList={mealList} width="100%" float="left" />
      <Submenu title={pmeat} priceList={pmeatList} width="55%" float="right" />
      <Submenu
        title={shawarma}
        priceList={shawarmaList}
        width="55%"
        float="right"
      />
      <Submenu title={grill} priceList={grillList} width="55%" float="left" />
      <Submenu
        title={takeaway}
        priceList={takeawayList}
        width="55%"
        float="left"
      />
    </>
  );
};

export default Body;
