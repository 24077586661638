import React from 'react'
import { Box, useMediaQuery } from '@mui/material'


const formatNumber = (number) => {
  return number.toLocaleString();
};


const Submenu = ({ title, priceList, width, float }) => {
  const isTab = useMediaQuery('(max-width:750px)')

  return (
      <Box 
        className= 'parent'
        sx={{ 
          width: 'auto',
          color: 'white', 
          margin: '20px', 
          marginTop: '0px',
          position: 'relative',
          zIndex: '10',
          }}>
            <Box 
              sx={{ 
                width: width,
                float: float, 
                }}>
              <Box 
                className= 'title'
                sx={{ width: '100%', fontSize: isTab ? '1rem' : '1.5rem', fontWeight: 100 }}>
                  {title}
              </Box>
              {priceList.map((item, index) => (
              <Box
                className= 'line'
                key={index}
                sx={{
                  width: '100%', 
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: index % 2 === 0 ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0)',
                  padding: '0px',
                  fontWeight: 100,
                }}
              >
                <Box sx={{ fontSize: isTab ? '0.7rem': '1rem', fontWeight: 200 }}>{item.product}</Box>
                <Box sx={{ fontSize: isTab ? '0.5rem': '0.75rem', fontWeight: 800 }}>{formatNumber(item.price)}</Box>
              </Box>
            ))}
          </Box>
      </Box>
  )
}

export default Submenu