import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { title, subtitle, address } from "../data/database";
import { colour } from "../data/colour";

const Header = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTab = useMediaQuery("(max-width:770px)");

  return (
    <Box
      sx={{
        color: "white",
        textAlign: "center",
        marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      <Box sx={{ fontSize: "50px", marginBottom: 0 }}>{title}</Box>
      <Box sx={{ fontSize: "15px", color: colour.accent, marginTop: -2 }}>
        {subtitle}
      </Box>
      <Box sx={{ fontSize: "10px" }}>{address}</Box>
    </Box>
  );
};

export default Header;
