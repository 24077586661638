import React from "react";
import { Box } from "@mui/material";
import ContactUs from "../components/ContactUs";
import SocialMediaBar from "../components/SocialMediaBar";

const contactDetails = [
  "+234 913 504 0637",
  "baniluxillumina2@gmail.com",
  "+234 816 944 4534",
];

const Footer = () => {
  return (
    <Box>
      <ContactUs contactDetails={contactDetails} />
      {/* <SocialMediaBar /> */}
    </Box>
  );
};

export default Footer;
